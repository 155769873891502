<template>
  <a
    rel="sponsored"
    :href="isApp ? '/link?d=' + encodeURIComponent(adLink) : adLink"
    target="_blank"
    v-show="selectedAd"
  >
    <img :src="adImage" border="0" />
  </a>
</template>

<script>
import { mapGetters } from "vuex";

import Http from "../mixins/Http";

export default {
  name: "AwinAd",
  data() {
    return {
      selectedAdIndex: null,
      possibleAds: [],
    };
  },
  mixins: [
    Http,
  ],
  props: {
    adsUrl: String,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
    }),
    selectedAd() {
      return this.possibleAds[this.selectedAdIndex];
    },
    adLink() {
      return this.selectedAd?.link;
    },
    adImage() {
      return this.selectedAd?.imageUrl;
    },
  },
  async beforeMount() {
    // These are not computed properties because they are only initialized
    // once, and they don't change after that.
    this.possibleAds = await this.sharedHttpGet(this.adsUrl);
    this.selectedAdIndex =
      Math.floor(this.possibleAds.length * Math.random()) % this.possibleAds.length;
  },
};
</script>
