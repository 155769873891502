import axios from "axios";

const REQUESTS_IN_PROGRESS = {};

function isServer() {
  return !(typeof window !== "undefined" && window.document);
}

export default {
    methods: {
        // Make an HTTP GET request, but reuses an existing request for that
        // URL if there's one currently in-progress.
        sharedHttpGet(url) {
            if (isServer()) {
                // Ensure this function is not called server-side, because then
                // `REQUESTS_IN_PROGRESS` would be shared by all users (because
                // requests would use the same Node.js process), and this would
                // risk leaking information from one user to another.
                return Promise.resolve(null);
            }

            let p = REQUESTS_IN_PROGRESS[url];

            if (!p) {
                p = axios.get(url).then((response) => response.data);

                REQUESTS_IN_PROGRESS[url] = p;

                p.then((data) => {
                    REQUESTS_IN_PROGRESS[url] = null;
                    return data;
                });
            }

            return p;
        },
    },
}
