<template>
  <div class="list-product__bba">
    <div class="bba-banner__header">
      <strong>Anuncio</strong>

      <span class="link link--underline" @click="upgradeToPremium()" v-if="!userIsVisitor">
        Quitar publicidad
      </span>
    </div>

    <Adsense :adType="adType" />
    <FallbackAd />
  </div>
</template>

<script>
import Adsense, { TYPE_IMAGE_SIDE } from "./Adsense.vue";
import { mapGetters } from "vuex";
import FallbackAd from "./FallbackAd.vue";

export default {
  name: "InFeedAd",
  components: {
    Adsense,
    FallbackAd,
  },
  data() {
    return {
      adType: TYPE_IMAGE_SIDE,
      showFallback: false,
    };
  },
  computed: {
    ...mapGetters({
      userIsVisitor: "userIsVisitor",
    }),
  },
  methods: {
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
  },
}
</script>
