<template>
  <div class="awin-ad">
    <mq-layout mq="m1200+">
      <AwinAd layout="m1200" :adsUrl="desktopAdsUrl" />
    </mq-layout>

    <mq-layout mq="m992">
      <AwinAd layout="m992" :adsUrl="desktopAdsUrl" />
    </mq-layout>

    <mq-layout mq="m768">
      <AwinAd layout="m768" :adsUrl="mobileAdsUrl" />
    </mq-layout>

    <mq-layout mq="m576">
      <AwinAd layout="m576" :adsUrl="mobileAdsUrl" />
    </mq-layout>

    <mq-layout mq="m480">
      <AwinAd layout="m480" :adsUrl="mobileAdsUrl" />
    </mq-layout>
  </div>
</template>

<script>
import AwinAd from "./AwinAd";

export default {
  name: "FallbackAd",
  components: {
    AwinAd,
  },
  data() {
    return {
      desktopAdsUrl: process.env.AWIN_ADS_DESKTOP,
      mobileAdsUrl: process.env.AWIN_ADS_MOBILE,
    };
  },
}
</script>
